const theme = {
  colors: {
    primary: {
      teal: "#00A19A",
      navy: "#343F84",
      graphite: "#393F5B"
    },
    secondary: {
      mediumNavy: "#485290",
      lightNavy: "#7078A8",
      lightTeal: "#E0F0EF",
      extraLightTeal: "#E5F5F4",
      disabled: "#E0E0E0",
      grey: "#757575",
      mediumGrey: "#ACACAC",
      lightGrey: "#F4F4F4",
      error: "#DF165D",
      lightError: "#FFF0F5",
      tableColor: "#FBFBFB",
      white: "#FFFFFF"
    }
  },
  fontSize: {
    h1: "30px",
    h2: "26px",
    h3: "22px",
    h4: "18px",
    h5: "14px",
    h6: "11px"
  },
  fontFamily: {
    nunitoSansBlack: "NunitoSans-Black",
    nunitoSansBold: "NunitoSans-Bold",
    nunitoSansMediumBold: "NunitoSans-ExtraBold",
    nunitoSansRegular: "NunitoSans-Regular",
  },
  devices: {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "425px",
    tablet: "768px",
    laptop: "1024px",
    laptopL: "1440px",
    desktop: "1600px",
    desktopL: "1920px",
  }
}

export default theme