import styled from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import { FormControl, Popper, TextField, Typography } from "@mui/material";
import theme from "../../../theme";

const CustomPopper = styled(Popper)`
  & .MuiAutocomplete-paper {
    border-radius: 0;
    box-shadow: none;
    padding: 5px 10px;
    outline: 2px solid ${theme.colors.primary.navy};
    margin-top: -1px;
  }

  & .MuiAutocomplete-option {
    color: ${theme.colors.secondary.grey};

    font-family: ${theme.fontFamily.nunitoSansRegular};
    font-size: ${theme.fontSize.h5};
    background: transparent;
    :hover {
      color: ${theme.colors.secondary.mediumNavy};
    }
  }
`;

const MyPopper = (props) => {
  return <CustomPopper {...props} placement="bottom" />;
};

export const StyledAutocomplete = ({ ...props }) => {
  return (
    <AutocompleteFormControl>
      <Autocomplete {...props} PopperComponent={MyPopper} />
    </AutocompleteFormControl>
  );
};

export const AutocompleteFormControl = styled(FormControl)`
  width: 100%;
  &.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: ${theme.colors.primary.navy};
  }
  &:hover {
    &.MuiInputLabel-formControl {
      color: ${theme.colors.secondary.mediumNavy};
    }
  }
`;

export const AutocompleteTextfield = styled(TextField)``;

export const AutocompleteMessage = styled(Typography)`
  color: ${theme.colors.primary.navy};
  font-family: ${theme.fontFamily.nunitoSansRegular};
  font-size: ${theme.fontSize.h6};
  background: transparent;
`;
