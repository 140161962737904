import React from "react";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import encodeKeyImg from "../../../assets/images/loadingKey.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme();
const styles = (theme) => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  heading: {
    fontSize: 26,
    color: "#393F5B",
    fontFamily: "NunitoSans-ExtraBold",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#393F5B",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <ThemeProvider theme={theme}>
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography className={classes.heading}>{children}</Typography>
      </MuiDialogTitle>
    </ThemeProvider>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(0),
    color: "#343F84",
    paddingBottom: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function EncodeDecodeLoading(prop) {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          onClose={prop?.onClose}
          aria-labelledby="customized-dialog-title"
          open={prop?.open}
          disableBackdropClick={true}
        >
          <DialogTitle id="customized-dialog-title" onClose={prop?.onClose}>
            {prop?.title} {prop?.audioName} in progress
          </DialogTitle>
          <DialogContent>
            <Typography
              gutterBottom
              style={{
                fontFamily: "NunitoSans-Regular",
                fontSize: 18,
                fontWeight: 500,
              }}
            >
              The speed of your internet connection and the size of the audio
              file may affect encoding and decoding times.
            </Typography>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="mt-4 mb-4"
            >
              <img
                src={encodeKeyImg}
                alt="Sonic Key"
                style={{ width: 190, height: 85 }}
              />
            </Grid>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
