import { makeStyles } from "@mui/styles";
import { Checkbox } from "@mui/material";
import React from "react";
import theme from "../../../theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme1 = createTheme();
const useStyles = makeStyles({
  root: {
    color: theme.colors.primary.navy,
    zIndex: "100",
    outline: "10px",
    "&:hover": {
      backgroundColor: theme.colors.secondary.lightTeal,
    },
    "&.Mui-checked": {
      color: theme.colors.primary.navy,
      "&:hover": {
        color: theme.colors.secondary.grey,
        backgroundColor: theme.colors.secondary.lightTeal,
      },
    },
    "&.Mui-disabled": {
      color: theme.colors.secondary.grey,
    },
  },
});

export default function AppCheckBox({ error, onChange, value, ...props }) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme1}>
      <Checkbox
        {...props}
        className={classes.root}
        error={!!error || undefined}
        onChange={onChange}
        checked={value}
        inputProps={{ "aria-label": "decorative checkbox" }}
      />
    </ThemeProvider>
  );
}
