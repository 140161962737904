import React from "react";
import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Container, Grid } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";
import Wave from "../../assets/images/wave-pages.svg";
import AppSideBar from "./AppSidebar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme();

export default function AppLayout({ children }) {
  const classes = useStyles();

  const [showSideBarMenu, setShowSideBarMenu] = React.useState(true);

  const toggleMenu = () => {
    setShowSideBarMenu(!showSideBarMenu);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />

        <Header id="headerContainer" />

        <Container maxWidth="xl" className={classes.container}>
          <Toolbar />
          <Grid id="container" className={classes.subContainer}>
            <Grid
              item
              id="sidebarContainer"
              className={
                !showSideBarMenu
                  ? classes.hiddenSideBarContainer
                  : classes.sidebarContainer
              }
            >
              <AppSideBar showMenu={showSideBarMenu} toggleMenu={toggleMenu} />
            </Grid>

            <Grid
              item
              id="pageContainer"
              className={
                !showSideBarMenu ? classes.widePageContent : classes.pagecontent
              }
            >
              <main>
                <div style={{ minHeight: "68vh" }}>{children}</div>
              </main>

              <Grid className="pt-4" id="footer">
                <Footer id="footerContainer" />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f2f2f2",
  },
  container: {
    paddingLeft: "3%",
    paddingRight: "3%",
    backgroundImage: `url(${Wave})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    backgroundPositionY: "top",
  },
  subContainer: {
    padding: "0% 4%",
    width: "100%",
    display: "flex",
  },
  sidebarContainer: {
    width: "190px",
    position: "fixed",
  },
  pagecontent: {
    flexGrow: 1,
    marginLeft: "190px",
    overflowY: "auto",
  },
  hiddenSideBarContainer: {
    width: "80px",
    position: "fixed",
    height: "100%",
  },
  widePageContent: {
    flexGrow: 1,
    marginLeft: "80px",
    overflowY: "auto",
  },
}));
