const awsconfig = {
  production: {
    aws_project_region: "eu-west-2",
    // "aws_cognito_identity_pool_id": "us-east-1:d039d9a0-5f87-4657-9cb5-ddb84203606c",
    aws_cognito_region: "eu-west-2",
    aws_user_pools_id: "eu-west-2_aZQqQKAtg",
    aws_user_pools_web_client_id: "6mg2ndtf5qrv7gqaei46v3ce0g",
    oauth: {
      // domain: "sonic-data-demo.auth.eu-west-2.amazoncognito.com",
      // scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      // redirectSignIn: 'http://localhost:3001/auth/signin/callback',
      // redirectSignOut: 'http://localhost:3001/auth/logout/callback',
      // responseType: 'code',
    },
  },
  staging: {
    aws_project_region: "",
    aws_cognito_identity_pool_id: "",
    aws_cognito_region: "",
    aws_user_pools_id: "",
    aws_user_pools_web_client_id: "",
    oauth: {},
  },
};

export default awsconfig[
  process.env.REACT_APP_ENV == "production" ? "production" : "staging"
];
