import React from "react";
import { withStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import theme from "../../../theme";
import { DoneSharp } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme1 = createTheme();

const IOSSwitch = withStyles(() => ({
  root: {
    width: (props) => props?.defaultsize || 120,
    height: 32,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(100%)",
      color: theme.colors.secondary.white,
      "& + $track": {
        backgroundColor: theme.colors.primary.navy,
        opacity: 1,
        border: "none",
      },
    },
  },
  thumb: {
    width: 30,
    height: 30,
  },
  track: {
    borderRadius: (props) => props?.defaultsize / 2,
    backgroundColor: theme.colors.secondary.mediumGrey,
    opacity: 1,
    "&:after, &:before": {
      color: theme.colors.secondary.white,
      fontSize: theme.fontSize.h6,
      fontFamily: theme.fontFamily.nunitoSansBold,
      position: "absolute",
      top: "9px",
    },
    "&:before": {
      content: (props) => props?.active || "''",
      left: "10%",
      opacity: 0,
    },
    "&:after": {
      content: (props) => props?.inactive || "''",
      right: "10%",
    },
  },
  checked: {
    width: (props) => props?.checkedsize || 69,
    "&$switchBase": {
      color: theme.colors.secondary.white,
      transform: "translateX(100%)",
    },
    "& $thumb": {
      backgroundColor: theme.colors.secondary.white,
    },
    "& + $track": {
      background: theme.colors.primary.navy,
      "&:before": {
        opacity: 1,
      },
      "&:after": {
        opacity: 0,
      },
    },
  },
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        checkedIcon={
          <div
            style={{
              width: 30,
              height: 30,
              backgroundColor: theme.colors.secondary.lightGrey,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DoneSharp
              style={{ color: theme.colors.primary.navy }}
              fontSize="small"
            />
          </div>
        }
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    </ThemeProvider>
  );
});

export default function AppToggleSwitch({
  checked,
  onChange,
  size,
  checkedSize,
  active,
  inActive,
  ...props
}) {
  return (
    <Grid>
      <IOSSwitch
        {...props}
        checked={checked}
        onChange={onChange}
        name="checked"
        defaultsize={size}
        checkedsize={checkedSize}
        active={active}
        inactive={inActive}
      />
    </Grid>
  );
}
