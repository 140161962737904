import React from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import LogoWithTextImg from "../../assets/images/Logo-colour-simple.png";
// import KeyImg from "../../assets/images/key-logo.png";
import SecondaryMenu from "./SecondaryMenu";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme();
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme?.zIndex?.drawer + 1,
    background: "transparent",
  },
  toolBar: {
    background: "white",
    padding: 0,
    padding: "1% 4%",
  },
  container: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
}));
export default function Header() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="sticky" className={classes.appBar} elevation={0}>
        <Container maxWidth="xl" className={classes.container}>
          <Toolbar className={classes.toolBar}>
            <img
              alt="logo"
              src={LogoWithTextImg}
              style={{ width: 80, cursor: "pointer" }}
              onClick={() => navigate("/dashboard")}
            />
            <div style={{ flexGrow: 1 }} />
            <SecondaryMenu />
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}
