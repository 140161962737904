import React from "react";
import { FormControl, MenuItem } from "@mui/material";
import {
  TimezoneSelect,
  TimezoneSelectInput,
} from "../../../../StyledComponents/StyledAppTextInput/StyledAppSelectInput";

export default function Timezone({
  formControlProps,
  labelText,
  id,
  labelProps,
  inputProps,
}) {
  return (
    <FormControl {...formControlProps} className="mt-2" variant="standard">
      {labelText !== undefined ? (
        <TimezoneSelectInput htmlFor={id} {...labelProps}>
          {labelText}
        </TimezoneSelectInput>
      ) : null}
      <TimezoneSelect
        style={{ boxShadow: "none", marginTop: "12px" }}
        id={id}
        {...inputProps}
      >
        <MenuItem style={{ cursor: "pointer" }} value="GMT">
          GMT, Time Zone
        </MenuItem>
        <MenuItem style={{ cursor: "pointer" }} value="LOCALE">
          Browser Local Time Zone
        </MenuItem>
      </TimezoneSelect>
    </FormControl>
  );
}
