const baseUrls = {
  localhost: {
    BASE_URL: "http://localhost:8000",
    API_URL: "http://localhost:8000",
  },
  // staging: {
  //   BASE_URL: "https://sonictempserver.arba-dev.uk/",
  //   API_URL: "https://sonictempserver.arba-dev.uk/",
  // },
  staging: {
    BASE_URL: "http://localhost:8000",
    API_URL: "http://localhost:8000",
  },
  // production: {
  //   BASE_URL: "https://jq5hvt7s-8000.inc1.devtunnels.ms/",
  //   API_URL: "https://jq5hvt7s-8000.inc1.devtunnels.ms/",
  // },
  production: {
    BASE_URL: "https://api-dev.sonicdata.com",
    API_URL: "https://api-dev.sonicdata.com",
  },
};

export default baseUrls[
  process.env.REACT_APP_ENV == "localhost"
    ? "localhost"
    : process.env.REACT_APP_ENV == "production"
    ? "production"
    : "staging"
];
